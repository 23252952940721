import { default as _91id_93b3UYTeZrz2Meta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/connection-drivers/[id].vue?macro=true";
import { default as indexwwFt7hQltSMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/connection-drivers/index.vue?macro=true";
import { default as indexKTsr9dpfdeMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/index.vue?macro=true";
import { default as indexQKhjIlxYizMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/merchants/[id]/index.vue?macro=true";
import { default as merchant_45accesseslkdALMvmweMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/merchants/[id]/merchant-accesses.vue?macro=true";
import { default as stores8MW61yHAPuMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/merchants/[id]/stores.vue?macro=true";
import { default as terminalsuA2sYIbxcZMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/merchants/[id]/terminals.vue?macro=true";
import { default as _91id_936EUueU1tfLMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/merchants/[id].vue?macro=true";
import { default as index3rSHLB3eazMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/merchants/index.vue?macro=true";
import { default as _91id_93tkjRmRx8dOMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/users/[id].vue?macro=true";
import { default as index2Udt7RBTPBMeta } from "/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/users/index.vue?macro=true";
export default [
  {
    name: "connection-drivers-id",
    path: "/connection-drivers/:id()",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/connection-drivers/[id].vue")
  },
  {
    name: "connection-drivers",
    path: "/connection-drivers",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/connection-drivers/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/index.vue")
  },
  {
    name: _91id_936EUueU1tfLMeta?.name,
    path: "/merchants/:id()",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/merchants/[id].vue"),
    children: [
  {
    name: "merchants-id",
    path: "",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/merchants/[id]/index.vue")
  },
  {
    name: "merchants-id-merchant-accesses",
    path: "merchant-accesses",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/merchants/[id]/merchant-accesses.vue")
  },
  {
    name: "merchants-id-stores",
    path: "stores",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/merchants/[id]/stores.vue")
  },
  {
    name: "merchants-id-terminals",
    path: "terminals",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/merchants/[id]/terminals.vue")
  }
]
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/merchants/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/users/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/var/www/sites/admin.pos2.halifax.dk/releases/20241101092912/pages/users/index.vue")
  }
]