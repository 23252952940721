import { defineNuxtPlugin } from "#app";
import { useAuth } from "~/stores/auth.js";

export default defineNuxtPlugin(async () => {
  const auth = useAuth();

  if (!(await auth.initialize())) {
    auth.logout();
  }
});
